import React from 'react';
import Main from './pages/main';
import Privacy from "./pages/privacy";
import Footer from './components/footer/footer.component';
import ScrollToTop from 'react-router-scroll-top'
import {Switch, Route} from 'react-router-dom';
import Logo from './images/baron-logo-black.png';
import { loadReCaptcha } from 'react-recaptcha-google';
import './App.css';

//Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54040713-3');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component{
  constructor(){
    super();
    this.state = {
        password: "",
        passwordCorrect: true,
        errorMessage: false
    }
}

handlePassword = (e)=>{
    this.setState({password: e.target.value});
}

handleSubmit = () =>{
  if(this.state.password === "BaronEsports2020!"){
    this.setState({passwordCorrect: true, errorMessage: false})
  }else{
    this.setState({errorMessage: true})
  }
}

  componentDidMount() {
    loadReCaptcha();
  } 

  render(){
    return (
      <div className="App">
        {this.state.passwordCorrect === false ? (
                    <div className="password-page">
                        <img src={Logo} alt="Baron Championship Rings"/>
                        <span style={{color: 'red', display:  this.state.errorMessage === false ? 'none' : 'initial'}}>Password Incorrect</span>
                        <div>
                        <input onChange={(e)=> this.handlePassword(e)} type="text" placeholder="Please enter a password"/>
                        <button onClick={()=> this.handleSubmit()}>Submit</button>
                        </div>
                    </div>
                ):(
              <div>
                <ScrollToTop>
                <Switch>
                  <Route path="/" component={Main } exact/>
                  <Route path="/Privacy" component={Privacy} />
                </Switch>
              </ScrollToTop>
              <Footer />
              </div>
                )
      }
      </div>
    );
  }
  
}

export default App;
