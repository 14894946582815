import React, {useEffect } from 'react';
import Hero from "../components/hero/hero.component";
import Design from "../components/design/design.component";
import SectionOne from "../components/sectionOne/sectionOne.component";
import SectionTwo from '../components/sectionTwo/sectionTwo.component';
import SectionThree from '../components/sectionThree/sectionThree.component';
import SectionFour from '../components/sectionFour/sectionFour.component';
import SectionFive from "../components/sectionFive/sectionFive.component";
import SectionSix from "../components/sectionSix/sectionSix.component";
import Testimonial from "../components/testimonial/testimonial.component"
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

const Main = () =>{
    useEffect(() => {
        document.title = "Esports Championship Rings, Custom Rings - Baron® Championship Rings"
     }, []);

    return(
        <React.Fragment>
            <Hero/>
            <SectionFour/>
            <SectionThree/>
            <Design/>
            <SectionOne/>
            <SectionFive/>
            <Testimonial/>
            <SectionTwo/> 
            <SectionSix/>
        </React.Fragment>
    )
}

export default Main;