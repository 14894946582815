import React from "react";
import ReactGA from 'react-ga';

//Images
import Logo from '../../images/Logo-landing-top.png'

//CSS
import "./hero.css";
import 'bootstrap/dist/css/bootstrap.min.css';


class Hero extends React.Component{
    constructor(){
        super();
        this.state = {
            error: null,
            mailSent: false,
            name: "",
            team: "",
            location: "",
            email: "",
            title: "",
            number: 0,
            incompleteForm: false,
    }
}

componentWillMount() {
    document.title = 'Baron Championship Rings - Esports'
  }
 
  closeModal = ()=>{
    this.setState({
            error: null,
            mailSent: false,
            name: "",
            team: "",
            location: "",
            email: "",
            title: "",
            number: "",
            incompleteForm: false,
    })
  }

  handleNameChange = (e) =>{
    this.setState({...this.state.name, name: e.target.value});
}

handleEmailChange = (e) =>{
    this.setState({...this.state.email, email: e.target.value});
}

handleTeamChange = (e) =>{
    this.setState({...this.state.team, team: e.target.value});
}

handleLocationChange = (e) =>{
    this.setState({...this.state.location, location: e.target.value});
}

handleTitleChange = (e) =>{
    this.setState({...this.state.title, title: e.target.value});
}

handleNumberChange = (e) =>{
    this.setState({...this.state.number, number: e.target.value});
}


  submitForm = (e) =>{
      e.preventDefault();
      if(this.state.name === "" || this.state.email === "" || this.state.team === "" || this.state.location === "" || this.state.title === "" ){
        this.setState({...this.state.incompleteForm, incompleteForm: true});
        return;
      }else{
          this.setState({...this.state.incompleteForm, incompleteForm: false});
      }

      fetch('https://esports.baronrings.com/mail/index.php', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
  },
  body: JSON.stringify(this.state),
}).then(response =>{
    if(response.status === 200){
        this.setState({...this.state.mailSent, mailSent: true});
        ReactGA.event({ event: 'conversion', action: 'AW-1022168872/HGGpCKys4M8BEKietOcD' });
    }
})
  .catch(error => console.log(error.message ));
  ReactGA.event({
    category: 'Esports CTA submit form button',
    action: 'User submitted form'
  });
  }

  packageDownload = () =>{
    ReactGA.event({
        category: 'ESports download collection button',
        action: 'a Package has been downloaded'
      });
  }
    render(){
        return(
            <header className="hero" name="hero">
                <a href="https://baronrings.com" target="_blank"  rel="noopener noreferrer"><img src={Logo} alt="Baron Championship Rings Logo"/></a>
                {/* <img className="mobile-header" src={MobileHeader} alt="Baron Championship Ring Esports Collection"/> */}
                <div className="infobox-container">
                    <div className="infobox-left">
                        <h1>WIN LIKE A PRO GAMER<br/> & WEAR YOUR ESPORTS<br/>CHAMPIONSHIP TITLE</h1>
                        <form className="form">
                    <div className="form-container">
                        {this.state.mailSent === false ? (
                        <React.Fragment>
                        {this.state.incompleteForm === true ? (
                            <div>
                            <p className="red oswald">*Please ensure all required fields are filled out</p>
                        </div>
                        ): null
                        }
                        <div className="input-container">
                        <div className="input-container-inner">
                            <div className="inner-one-line">
                            <label>*Name</label>
                            <input required type="text" name="name" onChange={this.handleNameChange}/>
                            </div>
                        
                            <div className="inner-one-line noPadRight">
                            <label>*Team/ Organization</label>
                            <input required type="text" name="organization" onChange={this.handleTeamChange}/>
                            </div>
                        </div>
                        <div className="input-container-inner">
                        <div className="inner-one-line">
                            <label>*City, State, Country</label>
                            <input required type="text" name="location" onChange={this.handleLocationChange}/>
                        </div>
                        <div className="inner-one-line noPadRight">
                        <label>*Email</label>
                        <input required type="text" onChange={this.handleEmailChange}/>
                        </div>
                        </div>
                        <div className="input-container-inner">
                        <div className="inner-one-line">
                            <label>*Championship Title</label>
                            <input required type="text" name="title" onChange={this.handleTitleChange}/>
                        </div>
                        <div className="inner-one-line noPadRight">
                            <label>Phone Number</label>
                            <input required type="number" name="number" onChange={this.handleNumberChange}/>
                        </div>
                        </div>
                        </div>
                        <div className="button-container">
                            <button onClick={this.submitForm} className="button-main oswald">SUBMIT MY CHAMPIONSHIP DETAILS</button>
                        </div>
                        </React.Fragment>
                        ) : (
                            <div className="thank-you-message">
                                <h1 className="oswald"> Thank you</h1>
                                <p className="oswald">One of our Championship Ring sales representatives will contact you soon.</p>
                            </div>
                        )                            
                    }
                    </div>
                </form>
                    </div>
                    <div className="infobox-right">
                   
                    </div>
                </div>
            </header>
        )
    }
}

export default Hero;