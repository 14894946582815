import React, {useState} from "react";

import VideoImage from '../../images/Benefits-Video Gaming Trophy-Team Liquid-Baron Championship Rings.png';
import Controller from '../../images/Video Gaming-gamepad-controller.png';
import "./sectionFour.css";

const SectionFour = () =>{
    return(
        <section className="info-container">
            <div className="info-inner">
              <img className="gaming_trophy" src={VideoImage} alt="Gold gaming controller 3d, Team Liquid Grand Slam Champions"/>
              <div className="info-inner-left">
                    <h2 className="oswald"><strong>SEE HOW BARON<span className="sup_large">&reg;</span> RINGS CAN DISPLAY <br/>YOUR ESPORTS CHAMPIONSHIP TITLE</strong></h2>
                    <p><strong>Our goal is to ensure that success and achievement in Esports is celebrated in game-chaning fashion by creating your one-of-a-kind Esports Championship Ring. See the benefits below:</strong></p>

                    <div>
                        <p className="no_margin no_padding"><strong>Fully Customized Designs</strong></p>
                        <p>Your Esports Championship Ring is designed with customized features reflecting your own Championship tournament, your gamertag, your team logo, etc.</p>
                    </div>

                    <div>
                        <p className="no_margin no_padding"><strong>Best Price</strong></p>
                        <p>Baron ensures you'll get a premium high quality Championship Ring at low cost, every time.</p>
                    </div>

                    <div>
                        <p className="no_margin no_padding"><strong>Simple Ordering Process</strong></p>
                        <p>Your Esports Championship Ring is designed with customized features reflecting your own Championship tournament, your gamertag, your team logo, etc.</p>
                    </div>

                    <div>
                        <p className="no_margin no_padding"><strong>Warranty</strong></p>
                        <p>Your Esports Championship Ring is designed with customized features reflecting your own Championship tournament, your gamertag, your team logo, etc.</p>
                    </div>
                    <div className="info-inner info-inner-bottom">
                    <div>
                        <img className="no_margin-left" src={Controller} alt="Gaming controller"/>
                        <span><strong>Team Rings</strong></span>
                    </div>
                    <div>
                        <img src={Controller} alt="Gaming controller"/>
                        <span><strong>Coach Rings</strong></span>
                    </div>
                    <div>
                        <img src={Controller} alt="Gaming controller"/>
                        <span><strong>Individual Rings</strong></span>
                    </div>
                    <div>
                        <img src={Controller} alt="Gaming controller"/>
                        <span><strong>High School Teams</strong></span>
                    </div>
                    <div>
                        <img className="no_margin-left" src={Controller} alt="Gaming controller"/>
                        <span><strong>Professional Leagues</strong></span>
                    </div>
               </div>
              </div>
            </div>
        </section>
    )
}

export default SectionFour;