import React from "react";
import "./footer.css";
import "./socicons.css";

const Footer = () =>{
    return(
        <footer className="et-l et-l--footer">
        <div className="et_builder_inner_content et_pb_gutters3">
    <div className="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular footer" >
            
            
            
            
                <div className="et_pb_row et_pb_row_0_tb_footer">
            <div className="et_pb_column et_pb_column_4_4 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
            
            
            <div className="et_pb_module et_pb_image et_pb_image_0_tb_footer">
            
            
            <span className="et_pb_image_wrap "><img src="https://test.baronrings.com/wp-content/uploads/2020/10/header_logo.png" alt="" title="header_logo" /></span>
        </div>
        </div> 
            
            
        </div> <div className="et_pb_row et_pb_row_1_tb_footer">
            <div className="et_pb_column et_pb_column_1_4 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">
            
            
            <div className="et_pb_module et_pb_text et_pb_text_0_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner" style={{color: "#fff"}}>REVOLUTIONIZING <span style={{color:"#b2a37d"}}>THE CHAMPIONSHIP </span>EXPERIENCE</div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner" style={{color: "#b2a37d"}}>ORDER YOUR CUSTOM CHAMPIONSHIP RINGS TODAY!</div>
        </div> 
        </div> <div className="et_pb_column et_pb_column_1_4 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough">
            
            
            <div className="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><i className="fas fa-pencil-alt"></i> <a href="https://test.baronrings.com/contact-us" title="Baron Championship Rings - Contact Us" style={{color: "#b2a37d"}}> CONTACT FORM</a></div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_3_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><i style={{color:"#b2a37d", paddingRight: ".1rem"}} className="fas fa-phone-alt"></i> TOLL FREE</div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_4_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><i style={{opacity:"0", paddingRight: ".5rem"}} className="fas fa-pencil-alt"></i><a href="tel:1-866-944-4089" title="Baron Championship Rings Telephone Number" style={{color: "#b2a37d"}}>1-866-944-4089</a></div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_5_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><i style={{color:"#b2a37d", paddingRight: ".5rem"}} className="fas fa-envelope"></i>EMAIL</div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_6_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><i style={{opacity:0, paddingRight: ".5rem"}} className="fas fa-pencil-alt"></i><a href="mailto:sales@baronrings.com" title="Baron Championship Ring - Sales Email" style={{color: "#b2a37d"}}>SALES@BARONRINGS.COM</a></div>
        </div>
        </div><div className="et_pb_column et_pb_column_1_4 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough">
            
            
            <div className="et_pb_module et_pb_text et_pb_text_7_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><p><a style={{color: "#b2a37d"}} href="https://baronrings.com/get-started" title="Baron Championship Rings - Get Started">GET STARTED</a></p></div>
        </div><div className="et_pb_module et_pb_text et_pb_text_8_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><p><a style={{color: "#b2a37d"}} href="https://baronrings.com/the-baron-locker-room" title="Baron Championship Rings - The Baron Lockeroom">LOCKER ROOM</a></p></div>
        </div><div className="et_pb_module et_pb_text et_pb_text_9_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><p><a style={{color: "#b2a37d"}} href="https://baronrings.com/our-mission" title="Baron Championship Rings - Our Mission">OUR MISSION</a></p></div>
        </div><div className="et_pb_module et_pb_text et_pb_text_10_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><p><a style={{color: "#b2a37d"}} href="https://baronrings.com/blog" title="Baron Championship Rings - Blog">BLOG</a></p></div>
        </div> <div className="et_pb_module et_pb_text et_pb_text_11_tb_footer et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner"><p><a style={{color: "#b2a37d"}} href="https://lnob.baronrings.com" target="_blank" rel="noopener noreferrer" title="Baron Championship Rings - Leave No One Behind">GIVE BACK</a></p></div>
        </div>
        </div><div className="et_pb_column et_pb_column_1_4 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
            
            
            <div className="et_pb_module et_pb_text et_pb_text_12_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner" style={{color: "#fff"}}>LET&#8217;S GET SOCIAL</div>
        </div><ul className="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0_tb_footer clearfix  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <li
        className='et_pb_social_media_follow_network_0_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-facebook et_pb_social_media_follow_network_0_tb_footer'><a
          href='https://facebook.com/baronrings'
          className='icon et_pb_with_border'
          title='Follow on Facebook'
           target="_blank" rel="noopener noreferrer"><span
            className='et_pb_social_media_follow_network_name'
            aria-hidden='true'
            >Follow</span></a></li><li
        className='et_pb_social_media_follow_network_1_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-twitter et_pb_social_media_follow_network_1_tb_footer'><a
          href='https://twitter.com/baronrings'
          className='icon et_pb_with_border'
          title='Follow on Twitter'
           target="_blank" rel="noopener noreferrer"><span
            className='et_pb_social_media_follow_network_name'
            aria-hidden='true'
            >Follow</span></a></li><li
        className='et_pb_social_media_follow_network_2_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-instagram et_pb_social_media_follow_network_2_tb_footer'><a
          href='https://instagram.com/baronchampionshiprings'
          className='icon et_pb_with_border'
          title='Follow on Instagram'
           target="_blank" rel="noopener noreferrer"><span
            className='et_pb_social_media_follow_network_name'
            aria-hidden='true'
            >Follow</span></a></li><li
        className='et_pb_social_media_follow_network_3_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-linkedin et_pb_social_media_follow_network_3_tb_footer'><a
          href='https://ca.linkedin.com/company/baron-championship-rings'
          className='icon et_pb_with_border'
          title='Follow on LinkedIn'
           target="_blank" rel="noopener noreferrer"><span
            className='et_pb_social_media_follow_network_name'
            aria-hidden='true'
            >Follow</span></a></li><li
        className='et_pb_social_media_follow_network_4_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-youtube et_pb_social_media_follow_network_4_tb_footer'><a
          href='https://www.youtube.com/channel/UCx9f2P7iKE9nDwqZpGXJM9A'
          className='icon et_pb_with_border'
          title='Follow on Youtube'
           target="_blank" rel="noopener noreferrer"><span
            className='et_pb_social_media_follow_network_name'
            aria-hidden='true'
            >Follow</span></a></li>
        </ul><div className="et_pb_module et_pb_text et_pb_text_13_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner" style={{color: "#fff"}}>SUBSCRIBE TO THE NEWSLETTER</div>
        </div><div className="et_pb_module et_pb_code et_pb_code_0_tb_footer">
            
            
            <div className="et_pb_code_inner"><span className="mailchimp_message">Thank you for subscribing</span>
<div style={{display:"flex"}}>

<input className="mailchimp_input" type="text" style={{color: "#fff", width: "85%", background: "transparent", padding: ".4rem", border: "1px solid #b2a37d"}}/>
<button className="mailchimp_button" style={{cursor: "pointer", background: "#b2a37d", border: "2px solid #b2a37d", fontweight: 'bold', fontSize: "1.2rem"}}>></button>
</div></div>
        </div>
        </div>
            
            
        </div>
            
            
        </div><div className="et_pb_with_border et_pb_section et_pb_section_1_tb_footer et_pb_with_background et_section_regular" >
            
            
            
            
                <div className="et_pb_row et_pb_row_2_tb_footer">
            <div className="et_pb_column et_pb_column_4_4 et_pb_column_5_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
            
            
            <div className="et_pb_module et_pb_text et_pb_text_14_tb_footer  et_pb_text_align_center et_pb_bg_layout_light">
            
            
            <div className="et_pb_text_inner" style={{color: "#b2a37d"}}>© BARON<span className="sup_small">&reg;</span> CHAMPIONSHIP RINGS 2020 | <a href="https://baronrings.com/privacy-policy" style={{color:"#b2a37d"}}>PRIVACY POLICY</a> </div>
        </div>
        </div>
            
            
        </div>
            
            
        </div></div>
</footer>
    )
}

export default Footer;