import React, {useState} from "react";
import { useMediaQuery } from 'react-responsive';
import ModalVideo from 'react-modal-video';

import PlayButton from '../../images/Play_BTN-1.png';
import PlayButtonLarge from '../../images/largeButton.png';
import PlayButtonLargeHovered from '../../images/largeButtonHovered.png';
import PlayButtonHovered from '../../images/Play_BTN-2-hover.png';
import Frostbite from '../../images/Frostbite Gaming D2.1 -Ring Rotation-1080X1080-Social-no shadow.jpg';
import Dreamhack from '../../images/Dreamhack-Masters-D.1.4-Ring-Rotation-Instagram-no shadow.jpg';
import Bighouse from '../../images/The Big House D.1.6-Instagram-no shadow.jpg';
import Kumite from '../../images/Kumite Gaming D2.2 Rotation Social-V2-2-no shadow.jpg';

import "./sectionFive.css";
import '../../components/video/modal-video.scss';


let vimeoId = null;

const SectionFive = () =>{
    const isDesktopOrLaptop = useMediaQuery({query: '(min-device-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' });

    const [isVideoOpen, changeVideoState] = useState(false);
    const [buttonColor1, setButtonState1] = useState(PlayButton);
    const [buttonColor2, setButtonState2] = useState(PlayButton);
    const [buttonColor3, setButtonState3] = useState(PlayButton);
    const [buttonColor4, setButtonState4] = useState(PlayButton);

    const [buttonColor5, setButtonState5] = useState(PlayButtonLarge);
    const [buttonColor6, setButtonState6] = useState(PlayButtonLarge);
    const [buttonColor7, setButtonState7] = useState(PlayButtonLarge);
    const [buttonColor8, setButtonState8] = useState(PlayButtonLarge);
    
    return (
            <section className="sectionFive-container">
                <div className="sectionFive-container-inner">
                     
                    { isTabletOrMobile && <>
                    <div className="package-container">
                <div className="package-container-inner one">
                    <div className="one">
                    <img src={Frostbite} alt="2020 Frostbite Championship Ring by Baron Championship Rings, obsidian black ring with blue & clear diamonds, bear claws, video" />
                    <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                    <img alt="Play Video Icon" className="play" name="play" onMouseOver={()=> setButtonState5(PlayButtonLargeHovered)} onMouseOut={()=> setButtonState5(PlayButtonLarge)} src={buttonColor5} onClick={function(){
                        vimeoId = '430350516'; 
                        changeVideoState(true);
                    }}/>
                    </div>
                    <p className="oswald bold">2020 Frostbite Championships</p>
                    <span className="roboto">1,600 Super Smash Bros. Ultimate players competed in the championship gaming series.</span>
                </div>
                <div className="package-container-inner">
                    <div className="two">
                        <img src={Dreamhack} alt="2019 DreamHack Masters Dallas Championship Ring by Baron Championship Rings, counter strike esports competition, video
" />
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img alt="Play Video Icon" className="play" onMouseOver={()=> setButtonState6(PlayButtonLargeHovered)} onMouseOut={()=> setButtonState6(PlayButtonLarge)} src={buttonColor6} onClick={function(){
                            vimeoId = '430350429'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold">2019 DreamHack Masters Championships</p>
                    <span className="roboto">Playing Counter-Strike, Team Liquid beat ENCE <br/>in the tournament 2-1.</span>
                </div>
                <div className="package-container-inner three">
                <div className="three">
                        <img src={Bighouse} alt="2019 The Big House Championship Ring by Baron Championship Rings, super smash brothers game tournaments, video, ncaa games" />
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img  alt="Play Video Icon" className="play" onMouseOver={()=> setButtonState7(PlayButtonLargeHovered)} onMouseOut={()=> setButtonState7(PlayButtonLarge)} src={buttonColor7} onClick={function(){
                            vimeoId = '430350628'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold">The Big House's 2019 Championship Ring</p>
                    <span className="roboto">The Big House is the largest Super Smash <br/>Bros. event series in the fall.</span>
                </div>
                <div className="package-container-inner four">
                    <div className="four">
                        <img src={Kumite} alt="Kumite Gaming Ring by Baron Championship Rings, Eibit Gaming with blue, red, & clear diamonds, fighting game tournament, pro gamer"/>
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img alt="Play Video Icon" className="play" onMouseOver={()=> setButtonState8(PlayButtonLargeHovered)} onMouseOut={()=> setButtonState8(PlayButtonLarge)} src={buttonColor8} onClick={function(){
                            vimeoId = '430350586'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold">Kumite Gaming Championship Ring</p>
                    <span className="roboto">One of the leading FGC tournament <br/>organizations in North America.</span>
                </div>
                </div>
</>}
{isDesktopOrLaptop && <>
    <div className="package-container">
                <div className="package-container-inner one">
                    <div className="one">
                    <img src={Frostbite} alt="2020 Frostbite Championships" />
                    <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                    <img alt="2020 Frostbite Championships" className="play" name="play" onMouseOver={()=> setButtonState1(PlayButtonHovered)} onMouseOut={()=> setButtonState1(PlayButton)} src={buttonColor1} onClick={function(){
                        vimeoId = '430350516'; 
                        changeVideoState(true);
                    }}/>
                    </div>
                    <p className="oswald bold white">2020 Frostbite Championships</p>
                    <span className="roboto">1,600 Super Smash Bros. Ultimate players competed in the championship gaming series.</span>
                </div>
                <div className="package-container-inner">
                    <div className="two">
                        <img src={Dreamhack} alt="2019 DreamHack Masters Championships" />
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img alt="2019 DreamHack Masters Championships" className="play" onMouseOver={()=> setButtonState2(PlayButtonHovered)} onMouseOut={()=> setButtonState2(PlayButton)} src={buttonColor2} onClick={function(){
                            vimeoId = '430350429'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold white">2019 DreamHack Masters Championships</p>
                    <span className="roboto">Playing Counter-Strike, Team Liquid beat ENCE <br/>in the tournament 2-1.</span>
                </div>
                <div className="package-container-inner three">
                <div className="three">
                        <img src={Bighouse} alt="The Big House's 2019 Championship Ring" />
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img  alt="The Big House's 2019 Championship Ring" className="play" onMouseOver={()=> setButtonState3(PlayButtonHovered)} onMouseOut={()=> setButtonState3(PlayButton)} src={buttonColor3} onClick={function(){
                            vimeoId = '430350628'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold white">The Big House's 2019 Championship Ring</p>
                    <span className="roboto">The Big House is the largest Super Smash <br/>Bros. event series in the fall.</span>
                </div>
                <div className="package-container-inner four">
                    <div className="four">
                        <img src={Kumite} alt="Kumite Gaming Championship Ring"/>
                        <ModalVideo channel='vimeo' isOpen={isVideoOpen} videoId={vimeoId} onClose={() => changeVideoState(false)} /> 
                        <img alt="Kumite Gaming Championship Ring" className="play" onMouseOver={()=> setButtonState4(PlayButtonHovered)} onMouseOut={()=> setButtonState4(PlayButton)} src={buttonColor4} onClick={function(){
                            vimeoId = '430350586'; 
                            changeVideoState(true);
                        }}/>
                    </div>
                    <p className="oswald bold white">Kumite Gaming Championship Ring</p>
                    <span className="roboto">One of the leading FGC tournament <br/>organizations in North America.</span>
                </div>
                </div>
</>}
                </div>
         </section>
        )
}


export default SectionFive; 

