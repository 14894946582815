import React, {useState} from "react";
import Testimonial from '../../images/Testimonial--M-v1.jpg';
import "./sectionTwo.css";


const SectionTwo = () =>{
    const [activeSlide, changeSlide] = useState("slide1");

        return(
            <section className="collection_container">
                <div className="collection_container_inner">
                    <div className="collection_grid">
                        {
                            activeSlide === "slide1" ? (
                                <h3 className="oswald">OUR AMAZING TEAM OF DESIGNERS, SALES AND 3D DRAFTERS ARE ALSO GAMERS THEMSELVES, WHO PLAY, LIVE, EAT, AND BREATHE ESPORTS.</h3>
                            ) : (
                                <React.Fragment>
                                    <blockquote className="oswald">"Our Esports Team Rings came out looking amazing! <br/> Thank you Baron<span className="collection_trademark">&reg;</span>  for doing such as a great job!"</blockquote>
                                    <cite className="oswald">Eibit Gaming</cite>
                                </React.Fragment>
                            )
                        }
                    
                    <img className="slide-left" alt=""/>
                    <img className="slide-right" alt=""/>
                    </div>
                    <div className="switch-box">
                        <div className={`slide-one ${activeSlide === "slide1" ? "active": "secondary"}`} onClick={()=> changeSlide("slide1")}></div>
                        <div className={`slide-two ${activeSlide === "slide2" ? "active": "secondary"}`} onClick={()=> changeSlide("slide2")}></div>
                    </div>
                </div>
         </section>
        )
}


export default SectionTwo; 