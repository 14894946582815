import React from "react";
import "./sectionThree.css";


const SectionThree = () =>{
        return(
            <section className="sectionThree_container">
                <div className="sectionThree_container_inner">
                    <h3 className="oswald"><strong>3 EASY STEPS TO ORDERING <br/>YOUR CHAMPIONSHIP ESPORTS RING</strong></h3>
                    <div className="section_three_columns">
                        <div className="section_three_columns_inner">
                        <p>1. To start the process, complete the <br/>Esports Championship Ring form.</p>
                        </div>
                    <div className="section_three_columns_inner">
                    <p>2. Download and review the Free Esports <br/>Championship Ring Collection for <br/>inspiration.</p>
                    </div>
                    <div className="section_three_columns_inner no_margin_bottom">
                    <p>3. An Esports Specialist will guide you through the designing of your <br/>Championship Ring.</p>
                    </div>
                    </div>
                    <div className="section_three_button_container">
                    <a href="https://esports.baronrings.com/2020-Esports-Sports-Package-V8-WEB.pdf" target="_blank"  rel="noopener noreferrer" className="button-main oswald">DOWNLOAD THE ESPORTS COLLECTION</a>
                    </div>
                    
                </div>
         </section>
        )
}


export default SectionThree; 

