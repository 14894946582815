import React from "react";
import { useMediaQuery } from 'react-responsive';
import DesignYourRing from '../../images/Design Your Esports Championship Rings-M.jpg';
import "./sectionOne.css";


const SectionOne = () =>{
        const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 992px)'});
        const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
        return(
                <React.Fragment>
        {isDesktopOrLaptop && <>
            <section className="section-container">
                    {/* <h2 className="oswald">DESIGNING YOUR CHAMPSIONSHIP RING IN THE BARON<span className="trademark-4">&reg;</span> GAMING LAB <br/>ALLOWS FOR CREATIVITY AND FULL CUSTOMIZATION.</h2>
                    <p className="roboto">Trust Baron<span className="trademark-4">&reg;</span> with your Esports custom rings. Using passion for Esports gaming, our in-house gamers will create championship celebration items reflecting your Championship title.</p>
                    <p className="roboto">Customizing your ring will reflect your Esports event and title. Your Gamertag, championship year, team league logo will truly represent your Championship gaming victory for life.</p>
                    <button className="oswald button button-main">OPEN BARON GAMING LAB</button> */}
         </section>
         </>}
         {isTabletOrMobile && <>
        <section className="section-container-mobile">
                <img src={DesignYourRing} alt="Baron Championship ring - Design your ring"/>
                    {/* <h2 className="oswald">EACH ESPORTS CHAMPIONSHIP RING MADE BY BARON<span className="trademark-4">&reg;</span>&nbsp;IS COMPLETELY UNIQUE</h2>
                    <p className="roboto">We deploy the experience and passion that our Sales and Design team have for gaming to help guide you through the process of creating a one of a kind ring. <br/><span className="extra">Fill out the form above to contact an Esports representative to discuss your ideas and vision.</span></p> */}
         </section>
        </>}
         </React.Fragment>
        )
}


export default SectionOne; 