import React, {useState}  from "react";

import Instagram from '../../images/Social Icons/Hover/Icon awesome-instagram.png';
import InstagramHovered from '../../images/Social Icons/Icon awesome-instagram.png';
import Facebook from '../../images/Social Icons/Hover/Icon awesome-facebook-f.png';
import FacebookHovered from '../../images/Social Icons/Icon awesome-facebook-f.png';
import Twitter from '../../images/Social Icons/Hover/Icon awesome-twitter.png';
import TwitterHovered from '../../images/Social Icons/Icon awesome-twitter.png';
import Linkedin from '../../images/Social Icons/Hover/Icon awesome-linkedin-in.png';
import LinkedinHovered from '../../images/Social Icons/Icon awesome-linkedin-in.png';
import Youtube from '../../images/Social Icons/Hover/Icon awesome-youtube.png';
import YoutubeHovered from '../../images/Social Icons/Icon awesome-youtube.png';

import "./sectionSix.css";

const scrollTo = () => window.scrollTo(0,0);

const SectionSix = () =>{
    const [instagramImage, setButtonState1] = useState(Instagram);
    const [facebookImage, setButtonState2] = useState(Facebook);
    const [twitterImage, setButtonState3] = useState(Twitter);
    const [linkedinImage, setButtonState4] = useState(Linkedin);
    const [youtubeImage,  setButtonState5] = useState(Youtube);
        return(
            <section className="sectionSix-container">
                <div className="sectionSix-container-inner">
                    <p className="oswald title">READY TO CREATE YOUR CUSTOM ESPORTS CHAMPIONSHIP RINGS?</p>
                    <p className="roboto">At Baron<span className="sup_small">&reg;</span> we understand rising up through the ranks, competing in big leagues takes time, teamwork, and strategy. We'll make sure to celebrate with you from all your smaller victories to win against the top teams of the world. Ensuring you to display your ultimate Esports Championship Ring prize!</p>
                    <button className="oswald button button-main" onClick={()=> scrollTo()}>CREATE MY ESPORTS CHAMPIONSHIP RING</button>

                    <p className="oswald follow">FOLLOW US ON SOCIAL AND SHARE YOUR ESPORTS CHAMPIONSHIP RING PHOTOS WITH US!</p>
                    <div className="social-container">
                        <div className="social-container-inner">
                        <a href="https://www.instagram.com/baronchampionshiprings" target="_blank"  rel="noopener noreferrer" className="button" ><img onMouseOver={()=> setButtonState1(InstagramHovered)} onMouseOut={()=> setButtonState1(Instagram)} src={instagramImage} alt="Instagram"/></a>
                        <p className="oswald">@baronchampionshiprings</p> 
                        </div>
                        <div className="social-container-inner">
                            <div>
                                <a className="padRight" href="https://www.facebook.com/baronrings/" target="_blank"  rel="noopener noreferrer" ><img onMouseOver={()=> setButtonState2(FacebookHovered)} onMouseOut={()=> setButtonState2(Facebook)} src={facebookImage} alt="Facebook"/></a>
                                <a href="https://twitter.com/BaronRings" target="_blank"  rel="noopener noreferrer" ><img onMouseOver={()=> setButtonState3(TwitterHovered)} onMouseOut={()=> setButtonState3(Twitter)} src={twitterImage} alt="Twitter"/></a>
                                <p className="oswald">@baronRings</p>
                            </div> 
                        </div>
                        <div className="social-container-inner">
                            <div>
                                <a className="padRight" href="https://www.linkedin.com/company/baron-championship-rings" target="_blank"  rel="noopener noreferrer" ><img onMouseOver={()=> setButtonState4(LinkedinHovered)} onMouseOut={()=> setButtonState4(Linkedin)} src={linkedinImage} alt="Linkedin"/></a>
                                <a href="https://www.youtube.com/channel/UCx9f2P7iKE9nDwqZpGXJM9A" target="_blank"  rel="noopener noreferrer" ><img onMouseOver={()=> setButtonState5(YoutubeHovered)} onMouseOut={()=> setButtonState5(Youtube)} src={youtubeImage} alt="Youtube"/></a>
                            </div>
                        <p className="oswald">Baron Championship Rings</p> 
                        </div>
                    </div>
                </div>
         </section>
        )
}


export default SectionSix; 