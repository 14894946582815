import React, {useState} from "react";
import "./testimonial.css";

import Controller from "../../images/Video Gaming-gamepad-controller.png"
const Testimonial = () =>{
        return(
            <section className="testimonial_container">
                 <div className="testimonial_inner">
                    <h5 className="oswald">AT BARON<span className="trademark-4">&reg;</span> WE KNOW THAT OUR MOST CREATIVE <br/>DESIGNS LAY WITHIN THE WORLD OF VIDEO GAMING.</h5>
                    <p><strong>Building strong relationship with Developers, Esports Tournaments, Professional Gamers and Teams. We are focused now more than ever on sustaining our competitive advantage with the introduction of the Baron<span className="sup_small">&reg;</span> Gaming Lab</strong></p>
                    <div className="testimonial_features">
                        <div className="testimonial_features_left">
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Real-Time Startegy:</strong> Starcraft, Warcraft, etc.</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Sports Games:</strong> Rocket league, Madden, etc</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Third-Person Shooters:</strong> Fortnite, Gears of War, etc.</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Other Games:</strong> Pokemon, World of Warcraft, etc.</span>
                        </div>
                        </div>

                        <div className="testimonial_features_right">
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Fighting Games:</strong> Super Smash Bros., Street Fighter, etc.</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>First-Person Shooters:</strong> Call of Duty series, Halo Series, etc.</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Multiplayer Online battle Arena:</strong> League of Legends, etc.</span>
                        </div>
                        <div className="testimonial_content">
                            <img src={Controller} alt="Gaming controller"/>
                            <span><strong>Racing:</strong> TrackMania, iRacing etc.</span>
                        </div>
                        </div>
                    </div>
            </div>
         </section>
        )
}


export default Testimonial; 