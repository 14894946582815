import React from 'react';
import Image from '../../images/Esports Game Lab-esports league, buy esports championship rings and apparel.jpg'
import './design.css';

const Design = () => {
    return(
        <section className="design_container">
            {/* <div className="design_inner_left">
            <h4 className="oswald white"><strong>DESIGNING YOUR CHAMPIONSHIP RING IN THE BARON<span className="sup_large" style={{fontSize: "1.3rem"}}>&reg;</span> GAMING LAB <br/>ALLOWS FOR CREATIVITY AND FULL CUSTOMIZATION.</strong></h4>
                <p className="roboto">Trust Baron<span className="sup_small">&reg;</span> with your Esports custom rings. Using passion for Esports gaming, our in-house gamers will create championship celebration items reflecting your Championship title.</p>
                <p className="roboto">Customizing your ring will reflect your Esports event and title. Your Gamertag, championship year, team league logo will truly represent your Championship gaming victory for life.</p>
                <a className="oswald button-main">OPEN BARON<span className="sup_large" style={{fontSize: ".8rem"}}>&reg;</span> GAMING LAB</a>
            </div>

            <div className="design_inner_right">
                <img src={Image} alt=""/>
            </div> */}
    </section>
    )
}

export default Design;